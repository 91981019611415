Modernizr.load([
    {
        test: Modernizr.mq('(min-width: 0px)'),
        nope: '/assets/frontend/js/respond.js'
    },
    {
        test: Modernizr.mq('(min-width: 0px)') || !window.matchMedia,
        yep: '/assets/frontend/js/matchMedia.js'
    }
]);
